import React from "react";
import SEO from "@src/components/SEO";
import Container from "@src/components/Container";
import { Flex } from "@src/components/Boxes";
import { Title, Space } from "@src/components/nessie-web";

const MentorMojoMail = () => {
  return (
    <>
      <SEO
        title="Mojo Mentor Mail"
        noindex={true}
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "Mojo Mentor Mail",
        }}
      />
      <Container paddingTop="20px" paddingBottom="60px">
        <Flex flexDirection="row" justifyContent="center" alignItems="center">
          <img
            src="https://static.classdojo.com/img/2023/02/MentorBadge_NoDate.png"
            width="80"
            style={{ marginRight: "20px" }}
            alt="Mentor Mojo Mail"
          />
          <Title size="2">Mentor Mojo Mail</Title>
        </Flex>
        <Space size="xl" />
        <video
          src="https://static.classdojo.com/img/2023/02/mentor-qr-message.mp4"
          width="100%"
          controls
          style={{ borderRadius: "24px" }}
        >
          <track default kind="captions" src="/captions/mentor-qr-message.vtt" srcLang="en" />
        </video>
      </Container>
    </>
  );
};

export default MentorMojoMail;
